// Chakra imports
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Select,
  Textarea,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Text,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  ButtonGroup,
} from "@chakra-ui/react";
import { React, useMemo } from "react";
import Card from "components/card/Card";
import search from "assets/img/icons/search.svg";
import addchatic from "assets/img/icons/add-chat.svg";
import filtersic from "assets/img/icons/filters.svg";
import profileimg from "assets/img/icons/profile.svg";
import clicn from "assets/img/icons/cl.svg";
import plusicn from "assets/img/icons/plus.svg";
import vdicn from "assets/img/icons/vd.svg";
import mcicn from "assets/img/icons/mc.svg";
import arwhiteicn from "assets/img/icons/arrow-white.svg";
import dltIcn from "assets/img/dlt-icon.svg";
import { auth, db } from "config/firebase";
import {
  addDoc,
  getFirestore,
  collection,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
// import { getFirestore } from 'firebase/firestore';
import LazyLoad from "react-lazyload";
// import { getDatabase,  set, onValue } from 'firebase/database';
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { storage } from "config/firebase";
import {
  query,
  orderBy,
  onSnapshot,
  limit,
  where,
  getDocs,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import moment, { duration } from "moment";
import { Get } from "api/admin.services";
import { BsArrowLeft, BsPause, BsPlay, BsStop } from "react-icons/bs";
import { useParams } from "react-router-dom";
import csvic from "assets/img/icons/csv.svg";
import vuImg from "assets/img/view-img.jpg";
import upldedimg from "assets/img/contentimages/content1.svg";
import { ReactMic } from "react-mic";
import { Tooltip } from "@chakra-ui/react";
import "./Chat.css";

// importing sidebar context to send pending msg data to sidebar
import { useMsgContext } from "contexts/PendindMsgContext";
import { CheckIcon } from "@chakra-ui/icons";
import { Post } from "api/admin.services";

export default function Chat() {
  let { pendingChats, setPendingChats } = useMsgContext();

  const [bigImage, setBigImage] = useState();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const { onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState("");
  const [msg, setMsg] = useState("");
  const [messages, setMessages] = useState([]);
  const [profile, setprofile] = useState();
  const [roomList1, setRoomList1] = useState([]);
  // console.log("🚀 ~ file: Chat.jsx:75 ~ Chat ~ roomList1:", roomList1);
  const [roomList, setRoomList] = useState([]);
  const [roomDetails, setRoomDetails] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("text");
  const [hide, setHide] = useState(false);
  const [activeRoomId, setActiveRoomId] = useState(null);
  const [activeRoomId1, setActiveRoomId1] = useState(null);
  const [fireStoreChats, setFireChats] = useState([]);
  const initialFocusRef = useRef();
  const csvUrl = localStorage.getItem("csvUrl");
  const messagesEndRef = useRef(null);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpenAdo,
    onOpen: onOpenAdo,
    onClose: onClose2,
  } = useDisclosure();

  // get profile
  const GetProfile = async () => {
    try {
      await Get(`admin/getProfile`).then((res) => {
        setprofile(res?.data?.profileData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const GetRoomIds = async () => {
    try {
      await Get(`admin/roomList?room_type=MediahousetoAdmin`).then((res) => {
        setRoomList1(res?.data?.data);
      });
    } catch (error) {}
  };

  const GetRoomIds1 = async () => {
    try {
      await Get(`admin/roomList?room_type=HoppertoAdmin`).then((res) => {
        setRoomList(res?.data?.data);
      });
    } catch (error) {}
  };

  // handle audio record
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");

  function generateUniqueFileName() {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}_${randomString}.mp3`;
  }

  const onStartRecording = () => {
    setIsRecording(true);
  };

  const onStopRecording = async (recordedBlob) => {
    setIsRecording(false);

    try {
      const storageRef = ref(storage, `media/${generateUniqueFileName()}`); // Generate a unique file name
      const snapshot = await uploadBytes(storageRef, recordedBlob.blob);
      // Get the download URL for the uploaded audio
      const audioURL = await getDownloadURL(snapshot.ref);
      setAudioURL(audioURL);
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    // console.log(
    //   "🚀 ~ file: Chat.jsx:161 ~ handleFileChange ~ selectedFile:",
    //   selectedFile
    // );
    if (selectedFile) {
      setOpenModal(true);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setOpenModal(false);
      setPreviewUrl("");
    }
    setFile(selectedFile);
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          const storageRef = ref(getStorage(), "thumbnails", file.name);
          uploadBytes(storageRef, blob)
            .then(() => {
              getDownloadURL(storageRef).then((downloadURL) => {
                resolve(downloadURL);
              });
            })
            .catch((error) => {
              reject(error);
            });
        }, "image/jpeg");
      };
    });
  };

  const handleSend = async () => {
    if (file) {
      const storageRef = ref(storage, `chat/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        let messageType = "";
        let thumbnailURL = "";

        if (file.type.startsWith("image/")) {
          messageType = "image";
        } else if (file.type.startsWith("video/")) {
          messageType = "video";
          // Generate the thumbnail and get the data URL
          const thumbnailDataUrl = await generateVideoThumbnail(file);
          thumbnailURL = thumbnailDataUrl;
        } else if (file.type.startsWith("audio/")) {
          messageType = "audio";
        }
        sendMessage(downloadURL, messageType, thumbnailURL);
        setOpenModal(false);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setFile(null);
      }
    } else if (msg.trim() !== "") {
      sendMessage(msg, "text");
      setOpenModal(false);
    } else if (audioURL) {
      sendMessage(audioURL, "recording");
      setAudioURL("");
    } else if (csvUrl) {
      sendMessage(csvUrl, "csv");
      localStorage.removeItem("csvUrl");
      setOpenModal(false);
    }
  };

  const sendMessage = async (message, messageType, thumbnailURL = "") => {
    const { uid, email } = auth.currentUser || {};
    const messageRef = collection(
      getFirestore(),
      "Chat",
      roomDetails?.room_id,
      "Messages"
    );
    const messageData = {
      messageId: new Date().getTime(),
      senderId: profile._id,
      senderName: profile.name,
      senderImage: `https://uat-presshope.s3.eu-west-2.amazonaws.com/public/adminImages/${profile?.profile_image}`,
      receiverId: roomDetails?.room_id,
      receiverName: `${roomDetails?.sender_id?.first_name || ""} ${
        roomDetails?.sender_id?.last_name || ""
      }`,
      receiverImage:
        process.env.REACT_APP_HOPPER_AVATAR +
        roomDetails?.sender_id?.avatar_details?.avatar,
      roomId: roomDetails.room_id,
      message: message,
      replyMessage: "Empty Comming Soon",
      messageType: messageType,
      videoThumbnail: thumbnailURL,
      duration: "duration",
      date: moment().utc().format("YYYY-MM-DD hh:mm:ss"),
      uploadPercent: 100,
      readStatus: "unread",
      replyType: type,
      latitude: 0.0,
      longitude: 0.0,
      isReply: "isReply",
      isLocal: 1,
      isAudioSelected: false,
    };

    try {
      await addDoc(messageRef, messageData);
      setMsg("");
      setFile(null);
      GetMessages(roomDetails?.room_id);
      await SendNotification(roomDetails?.room_id, messageType, `👋🏼 Hi ${roomDetails?.sender_id?.first_name} ${roomDetails?.sender_id?.last_name} (${roomDetails?.sender_id?.user_name}), you've got a new chat message from PRESSHOP🐰 `)
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const GetMessages = (roomId) => {
    const messageRef = collection(getFirestore(), "Chat", roomId, "Messages");
    const q = query(messageRef, orderBy("date"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log(
      //   "🚀 ~ file: Chat.jsx:294 ~ newMessages ~ newMessages:",
      //   newMessages
      // );
      setMessages(newMessages);
    });

    return unsubscribe;
  };

  // getting chat list from fireStore
  const getChat = async () => {
    const chatRef = collection(getFirestore(), "Chat"); // Assuming "Chat" is the collection name
    const q = query(chatRef, orderBy("date", "desc"));
    try {
      const querySnapshot = await getDocs(q);
      const chats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      // console.log("getchats", chats);
      setFireChats(chats);
      // return chats;
    } catch (error) {
      console.error("Error getting chats:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  useEffect(() => {
    GetRoomIds();
    GetRoomIds1();
    GetProfile();
    getChat();
  }, []);

  const handleSendClick = (event) => {
    event.preventDefault();
    handleSend();
  };

  const close = () => {
    setOpenModal(false);
    setFile("");
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const messageRef = doc(
        getFirestore(),
        "Chat",
        roomDetails?.room_id,
        "Messages",
        messageId
      );
      await deleteDoc(messageRef);
    } catch (error) {}
  };

  const SendNotification = async (receiver_id, notificationsTitle, notificationsMessage) => {
    try {
      let obj = {
        receiver_id: receiver_id,
        title: notificationsTitle,
        body: notificationsMessage,
      };

      await Post("admin/sendNotification", obj);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSeen = async (roomId, status) => {
    const messageRef = collection(getFirestore(), "Chat", roomId, "Messages");
    const querySnapshot = await getDocs(messageRef);
    const batch = writeBatch(getFirestore());
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, { readStatus: status });
    });
    try {
      await batch.commit();
      // console.log("Read status updated successfully.");
      GetMessages(roomId);
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };

  const fireStoreChatList = useMemo(() => {
    return fireStoreChats.map((obj) => obj.data);
  }, [fireStoreChats]);

  //extracting the number of unseen msgs
  const unSeenMsg = useMemo(() => {
    let pendingmsg = 0;
    fireStoreChatList.forEach((obj) => {
      if (obj.readStatus === "unread") {
        pendingmsg++;
      }
    });
    return pendingmsg;
  }, [fireStoreChatList]);
  // console.log("🚀 ~ file: Chat.jsx:430 ~ unSeenMsg ~ unSeenMsg:", unSeenMsg)

  useEffect(() => {
    if (fireStoreChatList.length > 0) {
      setPendingChats(unSeenMsg);
    }
  }, [fireStoreChatList]);

  useEffect(() => {
    if (roomList1.length > 0) {
      setRoomDetails(fireStoreChatList[0]);
      GetMessages(fireStoreChatList[0]?.roomId);

      setActiveRoomId(fireStoreChatList[0]?.roomId);
      setHide(true);
    }
  }, [fireStoreChatList]);

  //update chatlist
  const updateChatList = (roomId) => {
    setFireChats((prev) => {
      return prev.map((obj) => {
        if (obj?.data?.roomId === roomId) {
          return { ...obj, data: { ...obj.data, readStatus: "seen" } };
        } else {
          return obj;
        }
      });
    });
  };

  // console.log("hdhckhchsdcdxhhkzxbsj", messages);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {localStorage.getItem("special_navigate") === true ||
        localStorage.getItem("special_navigate") === "true" ? (
          <div className="back_link">
            <a
              onClick={() => {
                window.history.back();
              }}
            >
              <BsArrowLeft />
              <span>Back</span>
            </a>
          </div>
        ) : null}
        {/* {console.log(
          "localStorage.getItemspecial_navigate",
          localStorage.getItem("special_navigate")
        )} */}
        <Flex mb="0px" gap="25px">
          <Card
            className="cms_left_card chat_wrap"
            direction="column"
            w="450px"
            px="0px"
            mb="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <div className="chat_srch">
              <Input placeholder="Search" />
              <img src={search} className="srch_ic" alt="" />
              <a onClick={onOpen}>
                <img src={addchatic} className="add_chat_ic" alt="" />
              </a>
              <img src={filtersic} className="filter_chat_ic" alt="" />
            </div>
            <div className="chat_tabs_wrap">
              <Tabs
                variant="unstyled"
                // onChange={(e) => {
                //   if (e === 0) {
                //     GetRoomIds("MediahousetoAdmin")
                //   }
                //   else if (e === 1) {
                //     GetRoomIds("HoppertoAdmin")
                //   }
                // }}
              >
                <TabList>
                  <Tab
                    _selected={{ color: "white", bg: "#000" }}
                    bg="#F3F5F4"
                    onChange={() => {}}
                  >
                    <span>Publications</span>
                  </Tab>

                  <Tab
                    _selected={{ color: "white", bg: "#000" }}
                    bg="#F3F5F4"
                    onChange={() => {}}
                  >
                    <span>Hoppers </span>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel className="chat_panels">
                    {
                      <div className="chat_items">
                        {roomList1 &&
                          roomList1.map((curr) => {
                            return (
                              <div
                                className={`chat_itm ${
                                  activeRoomId === curr?._id ? "active" : ""
                                }`}
                                key={curr?._id}
                                onClick={() => {
                                  setRoomDetails(curr);
                                  GetMessages(curr?.room_id);
                                  handleSeen(curr?.room_id, "seen");
                                  setActiveRoomId(curr?._id);
                                  setHide(true);
                                }}
                              >
                                <div className="cht_img">
                                  <img
                                    src={
                                      curr?.sender_id?.admin_detail
                                        ?.admin_profile
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="cht_txt">
                                  <div className="hding">
                                    <p className="chat_name">
                                      {`${curr?.sender_id?.first_name} ${curr?.sender_id?.last_name}`}{" "}
                                      <span className="pblc">
                                        &nbsp; ({curr?.sender_id?.company_name})
                                      </span>
                                    </p>
                                    <p className="msg" key={curr?.messageId}>
                                      {messages[messages?.length - 1]
                                        ?.messageType === "video"
                                        ? "video"
                                        : messages[messages?.length - 1]
                                            ?.messageType === "image"
                                        ? "image"
                                        : messages[messages?.length - 1]
                                            ?.messageType === "csv"
                                        ? "document"
                                        : messages[messages?.length - 1]
                                            ?.messageType === "text"
                                        ? messages[messages?.length - 1]
                                            ?.message
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="chat_time">
                                    <p>
                                      {moment
                                        .utc(
                                          messages[messages?.length - 1]?.date
                                        )
                                        .local()
                                        .format("hh:mm:A")}
                                    </p>
                                    <p>
                                      {moment
                                        .utc(
                                          messages[messages?.length - 1]?.date
                                        )
                                        .local()
                                        .format("DD MMMM YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    }
                  </TabPanel>

                  <TabPanel className="chat_panels">
                    <div className="chat_items">
                      {roomList &&
                        roomList.map((curr) => {
                          return (
                            <div
                              className={`chat_itm ${
                                activeRoomId1 === curr?._id ? "active" : ""
                              }`}
                              key={curr?._id}
                              onClick={() => {
                                setRoomDetails(curr);
                                GetMessages(curr?.room_id);
                                setHide(true);
                                setActiveRoomId1(curr?._id); // Set the active room ID
                                handleSeen(curr?.room_id, "read");
                              }}
                            >
                              <div className="cht_img">
                                <img
                                  src={
                                    process.env.REACT_APP_HOPPER_AVATAR +
                                    curr?.sender_id?.avatar_details?.avatar
                                  }
                                  alt="user"
                                />
                              </div>
                              <div className="cht_txt">
                                <div className="hding">
                                  <p className="chat_name">
                                    {`${curr?.sender_id?.first_name} ${curr?.sender_id?.last_name}`}{" "}
                                    <span className="pblc">
                                      &nbsp; ({curr?.sender_id?.user_name})
                                    </span>
                                  </p>
                                  <p className="msg">
                                    {messages[messages?.length - 1]
                                      ?.messageType === "video"
                                      ? "video"
                                      : messages[messages?.length - 1]
                                          ?.messageType === "image"
                                      ? "image"
                                      : messages[messages?.length - 1]
                                          ?.messageType === "csv"
                                      ? "document"
                                      : messages[messages?.length - 1]
                                          ?.messageType === "text"
                                      ? messages[messages?.length - 1]?.message
                                      : ""}
                                  </p>
                                </div>
                                <div className="chat_time">
                                  <p>
                                    {moment
                                      .utc(messages[messages?.length - 1]?.date)
                                      .local()
                                      .format("hh:mm:A")}
                                  </p>
                                  <p>
                                    {moment
                                      .utc(messages[messages?.length - 1]?.date)
                                      .local()
                                      .format("DD MMMM YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </Card>

          <Card
            className="chat_right"
            w="100%"
            px="0px"
            mb="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <div className="chating">
              {messages &&
                messages.map((curr) => {
                  return (
                    <div className="single_chat" key={curr?._id}>
                      <div
                        className="chat-dlt"
                        onClick={() => handleDeleteMessage(curr?.id)}
                      >
                        <img src={dltIcn} alt="" />
                      </div>
                      <div className="single_chat_img">
                        <img
                          src={curr?.senderImage}
                          className="chat_user_img"
                          alt="user"
                        />
                      </div>
                      <div className="cht_usr_txt">
                        <p className="usr_name">
                          {curr?.senderName}
                          <span className="msg_time">
                            {moment.utc(curr?.date).local().format("hh:mm:A")}
                          </span>
                        </p>

                        {curr.messageType &&
                        curr.messageType === "image" &&
                        curr?.uploadPercent === 100 ? (
                          <LazyLoad>
                            <span
                              onClick={() => {
                                onOpen1();
                                setBigImage(curr.message);
                              }}
                            >
                              <img
                                src={curr.message}
                                alt="User"
                                className="cht_in_img"
                              />
                            </span>
                          </LazyLoad>
                        ) : curr.messageType === "video" ? (
                          <div className="pos_rel wdth_ft">
                            <span>
                              {!videoPlaying ||
                              currentVideo !== curr.messageId ? (
                                <div key={curr.messageId}>
                                  <span className="vdo_thumb_wrap">
                                    <BsPlay
                                      className="thumbnail_play"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setVideoPlaying(true);
                                        setCurrentVideo(curr.messageId);
                                      }}
                                    />
                                  </span>
                                  <img
                                    className="cht_in_vdo"
                                    src={curr.videoThumbnail}
                                    alt="Video Thumbnail"
                                  />
                                </div>
                              ) : (
                                <video
                                  key={curr.messageId}
                                  className="cht_in_vdo"
                                  src={curr.message}
                                  controls
                                  onEnded={() => {
                                    setVideoPlaying(false);
                                    setCurrentVideo(null);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        ) : curr.messageType === "audio" ? (
                          curr.uploadPercent === 100 ? (
                            <audio controls>
                              <source src={curr?.message} type="audio/mpeg" />
                            </audio>
                          ) : null
                        ) : curr.messageType === "csv" ? (
                          <div>
                            <a href={curr.message} download="file.csv">
                              <img src={csvic} className="dwnld_csv_ic" />
                            </a>
                          </div>
                        ) : curr.messageType === "recording" ? (
                          curr.uploadPercent === 100 ? (
                            <audio controls>
                              <source src={curr?.message} type="audio/mpeg" />
                            </audio>
                          ) : null
                        ) : (
                          <p className="mb-0 msg">
                            {curr.message}
                            {curr.readStatus == "unread" ? (
                              <span className="msgChecks">
                                <CheckIcon />
                                <CheckIcon />
                              </span>
                            ) : (
                              <>
                                <span className="msgChecks">
                                  <CheckIcon />
                                  <CheckIcon />
                                </span>
                                <span className="msgChecks">
                                  <CheckIcon />
                                  <CheckIcon />
                                </span>
                              </>
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div ref={messagesEndRef} />
            </div>

            <>
              {hide && (
                <form onSubmit={handleSendClick}>
                  <div className="type_msg_wrap">
                    <div className="typing_wrap">
                      <img
                        src={`https://uat-presshope.s3.eu-west-2.amazonaws.com/public/adminImages/${profile?.profile_image}`}
                        alt="profile"
                        className="type_profile"
                      />
                      <Input
                        w="66%"
                        className="msg_inp"
                        value={msg}
                        placeholder="Type here…"
                        border="unset"
                        onChange={(e) => {
                          setMsg(e.target.value);
                          setType("text");
                        }}
                      />
                      <div className="type_right_icns" type="file">
                        <input
                          type="file"
                          id="cht_add_img"
                          className="cht_file_inp"
                          onChange={handleFileChange}
                        />
                        <label htmlFor="cht_add_img" className="cht_fl_inp_lbl">
                          <img
                            src={plusicn}
                            alt="profile"
                            className="plus_profile"
                          />
                        </label>
                        <Popover
                          initialFocusRef={initialFocusRef}
                          placement="bottom"
                          closeOnBlur={false}
                        >
                          <PopoverTrigger>
                            <Button className="tooltip">
                              <img
                                src={mcicn}
                                alt="profile"
                                className="plus_profile"
                              />
                            </Button>
                          </PopoverTrigger>

                          <PopoverContent
                            color="Black"
                            bg="#fff"
                            borderColor="unset"
                          >
                            <PopoverHeader
                              pt={4}
                              fontFamily="AirbnbBold"
                              border="0"
                            >
                              Record Audio
                            </PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton mt={"13px"} />
                            <PopoverBody>
                              <Flex mt={"10px"} justify={"space-evenly"}>
                                <Button
                                  className="rec_aud_btn"
                                  color={"white"}
                                  onClick={onStartRecording}
                                  disabled={isRecording}
                                >
                                  {" "}
                                  <BsPlay fontSize={"20px"} /> Start
                                </Button>
                                <Button
                                  className="rec_aud_btn"
                                  color={"white"}
                                  onClick={onStopRecording}
                                  disabled={!isRecording}
                                >
                                  <BsPause fontSize={"20px"} /> Stop
                                </Button>
                              </Flex>
                              <Flex>
                                <ReactMic
                                  record={isRecording}
                                  className="sound-wave"
                                  onStop={onStopRecording}
                                />
                              </Flex>
                            </PopoverBody>
                            <PopoverFooter
                              border="0"
                              d="flex"
                              alignItems="center"
                              justifyContent="end"
                              pb={4}
                            >
                              {/* <Box fontSize='sm'>Step 2 of 4</Box> */}
                              <ButtonGroup>
                                {audioURL && (
                                  <Button
                                    className="theme_btn"
                                    p={"10px 20px"}
                                    ref={initialFocusRef}
                                    onClick={handleSendClick}
                                  >
                                    Send
                                  </Button>
                                )}
                              </ButtonGroup>
                            </PopoverFooter>
                          </PopoverContent>
                        </Popover>

                        <Button type="submit" className="snd_btn">
                          <img
                            src={arwhiteicn}
                            alt="profile"
                            className="plus_profile"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </>
          </Card>
        </Flex>
      </Box>

      {openModal && (
        <Modal
          className="action_modal_wrap"
          isOpen={openModal}
          onClose={onClose}
          show
        >
          <ModalOverlay />
          <ModalContent className="action_modal_cont mdl_wdth">
            <ModalBody>
              <Text fontFamily="AirbnbBold" fontSize="35px" mb="43px">
                Preview media
              </Text>
              <div className="action_modal_body">
                <div className="dtl_wrap mdl_itms">
                  <Flex
                    className="cht_uplded_media"
                    px="0px"
                    justify="start"
                    gap="20px"
                    mb="0px"
                    align="center"
                    flexWrap="wrap"
                  >
                    {file.type.startsWith("video/") ? (
                      <video className="cht_in_vdo" src={previewUrl} controls />
                    ) : file.type.startsWith("image/") ? (
                      <img src={previewUrl} alt="" />
                    ) : (
                      "audio"
                    )}
                  </Flex>
                </div>
                <div className="save_btn_wrap">
                  <Button className="btn_bg btn_close" onClick={close}>
                    Close
                  </Button>
                  <Button className="btn_bg" onClick={handleSend}>
                    Send
                  </Button>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={isOpen1} onClose={onClose1}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="preview-media imgs">
              <img src={bigImage} alt="" />
            </div>
          </ModalBody>

          <ModalFooter className="mdl-ftr">
            <div className="save_btn_wrap">
              <Button
                variantColor="blue"
                mr={3}
                onClick={onClose1}
                className="chakra-button btn_bg"
              >
                Close
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
