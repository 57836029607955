import React, { useEffect, useState } from "react";
import { createContext } from "react";
import {
  query,
  orderBy,
  getDocs,
  collection,
  getFirestore,
} from "firebase/firestore";
import { Get } from "api/admin.services";

export const PendingMsgContext = createContext();

//context provider
export const PendingMsgProvider = ({ children }) => {
  let [pendingChats, setPendingChats] = React.useState(null);
  const [fireStoreChats, setFireChats] = useState(null);
  const [notificationOther, setNotificationOther] = useState(null);
  const [pendingNotifications, setPendingNotifications] = useState(null);

  // getting chat list from fireStore
  const getChat = async () => {
    const chatRef = collection(getFirestore(), "Chat"); // Assuming "Chat" is the collection name
    const q = query(chatRef, orderBy("date", "desc"));
    try {
      const querySnapshot = await getDocs(q);
      const chats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setFireChats(chats);

      // return chats;
    } catch (error) {
      console.error("Error getting chats:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  //getting list for extracting pending unseen msg
  const getNotificationOTHERS = async () => {
   
    try {
      await Get(`admin/getnotification?type=received`).then(
        (res) => {
          console.log("check", res?.data?.data)
          setNotificationOther(res?.data?.data);
          
        }
      );
    } catch (error) {
     console.log(error)
    }
  };

  useEffect(() => {
    getChat();
    getNotificationOTHERS();
  }, []);

  useEffect(() => {
    if (fireStoreChats) {
      let pendingmsg = 0;
      fireStoreChats
        .map((obj) => obj.data)
        .forEach((obj) => {
          if (obj?.readStatus === "unread") {
            pendingmsg++;
          }
        });
      setPendingChats(pendingmsg);
    }
  }, [fireStoreChats]);

  useEffect(() => {
    if (notificationOther) {
      let pendingNotif  = 0;
      notificationOther
        .forEach((obj) => {
          if (obj?.is_read === false){
           
            pendingNotif++;
          }
        });
        setPendingNotifications(pendingNotif);
    }
  }, [notificationOther]);

  return (
    <PendingMsgContext.Provider
      value={{
        pendingChats,
        setPendingChats,
        pendingNotifications,
        setPendingNotifications,
      }}
    >
      {children}
    </PendingMsgContext.Provider>
  );
};

export const useMsgContext = () => React.useContext(PendingMsgContext);
